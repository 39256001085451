
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Footer",
  setup() {
    const route = useRoute();

    const name = ref(route.name);

    watch(
      () => route.name,
      () => {
        name.value = route.name;
      }
    );
    return {
      name,
    };
  },
});
