<template>
  <footer>
    <ul class="footer-nav">
      <li
        :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'first',
        }"
      >
        <router-link :to="{ name: 'Index' }">
          <img src="@/assets/images/icon1.png" alt="首页灰" />
          <img
            class="active-status"
            src="@/assets/images/icon1-1.png"
            alt="首页红"
          />
          首页
        </router-link>
      </li>
      <li
        :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'second',
        }"
      >
        <router-link :to="{ name: 'ProductCategory' }">
          <img  src="@/assets/images/icon2.png" alt="产品灰" />
          <img
            class="active-status"
            src="@/assets/images/icon2-1.png"
            alt="产品红"
          />

          产品分类
        </router-link>
      </li>
      <li
        :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'third',
        }"
      >
        <router-link :to="{ name: 'Mine' }">
          <img  src="@/assets/images/icon3.png" alt="" />
          <img
            class="active-status"
            src="@/assets/images/icon3-1.png"
            alt=""
          />
          个人中心
        </router-link>
      </li>

      <li
        :class="{ 'footer-li': true,
        active: $route.meta.footActive == 'four', }"
      >
        <router-link :to="{ name: 'ShopCar' }">
          <img
            src="@/assets/images/icon4.png"
            alt=""
          />
          <img
            class="active-status"
            src="@/assets/images/icon4-1.png"
            alt=""
          />
          购物车
        </router-link>
      </li>
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
        >
          <img src="@/assets/images/icon5.png" alt="" />
          <img class="active-status" src="@/assets/images/icon5-1.png" alt="" />

          客服
        </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Footer",
  setup() {
    const route = useRoute();

    const name = ref(route.name);

    watch(
      () => route.name,
      () => {
        name.value = route.name;
      }
    );
    return {
      name,
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;

  background-color: #fff;
  z-index: 10;
  a {
    font-size: 14px;
    color: #666;
    display: block;
  }
}

.footer-li {
  float: left;
  width: 20%;
  text-align: center;
  position: relative;
  .mine-wapper {
    width: 46px;
    height: 46px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    top: -11.25px;
    margin: 0 auto;
    padding-top: 5px;
    box-sizing: border-box;
    img {
      width: 35px;
      height: 35px;
      display: block;
      margin: 0 auto;
    }
  }
  img {
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px auto;
  }
  svg {
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px auto;
  }

  .active-status {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -10px;
    top: 0;
    z-index: 10;
    background-color: #fff;
  }
  svg.active-status {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -10px;
    top: 0;
    z-index: 10;
    background-color: #fff;
  }
}
.footer-nav {
  .active {
    a {
      color: #b9135a;
    }

    .active-status {
      display: block;
    }
  }
}
</style>
